<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="hasRisk" @change="loadUsers(true)">
                <el-radio-button :label="true">有风险</el-radio-button>
                <el-radio-button :label="false">无风险</el-radio-button>
                <el-radio-button :label="null">未评估</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadUsers(true)" />
                </div>
            </div>
            <el-table :data="users.data" class="mt-2">
                <el-table-column prop="phone" label="注册登录手机号" sortable />
                <el-table-column prop="companyName" label="水发公司" sortable />
                <el-table-column prop="name" label="企业名称" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.id" target="_blank">
                            {{scope.row.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="idCard" label="统一社会信用代码" sortable />
                <el-table-column label="操作" fixed="right" width="80">
                    <template #default="scope">
                        <el-button type="text" @click="showUserRisksDialog(scope.row)">风险预警</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="users.totalRecords"
                :current-page="usersPagination.currentPage" :page-size="usersPagination.pageSize" class="mt-3"
                @current-change="usersPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="userRisksDialogVisible" :title="user.name+' 风险预警'" :close-on-click-modal="false"
            width="960px">
            <user-risks v-if="userRisksDialogVisible" :registered-user-id="user.id" />
        </el-dialog>
    </div>
</template>

<script>
    import UserRisks from './components/UserRisks.vue';

    export default {
        data() {
            return {
                hasRisk: true,
                key: null,
                users: {},
                usersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                user: {},
                userRisksDialogVisible: false,
            };
        },
        components: { UserRisks },
        methods: {
            async loadUsers(resetPage = false) {
                if (resetPage) {
                    this.usersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/RegisteredUser/GetRiskyEnterpriseUsers', {
                    params: {
                        companyId: this.$store.state.companyId,
                        hasRisk: this.hasRisk,
                        key: this.key,
                        pageIndex: this.usersPagination.currentPage - 1,
                        pageSize: this.usersPagination.pageSize,
                    }
                });
                this.users = response.data;
            },
            usersPaginationCurrentChange(page) {
                this.usersPagination.currentPage = page;
                this.loadUsers();
            },
            showUserRisksDialog(user) {
                this.user = JSON.parse(JSON.stringify(user));
                this.userRisksDialogVisible = true;
            },
        },
        created() {
            this.loadUsers();
        },
    };
</script>